<template></template>
<script>
import ApiService from '@/services/api.service'

export default {
    methods: {
        joinGameTeam() {
            const teamId = this.$route.params.teamId

            ApiService.joinTeam(teamId).then(
                (response) => {
                    this.$bvModal
                        .msgBoxOk('You have joined the team!', {
                            title: 'Success',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({
                                    name: 'gamePlay',
                                    params: {
                                        teamId: teamId,
                                    },
                                })
                                .catch((err) => {})
                        })
                },
                (error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({ name: 'game' })
                                .catch((err) => {})
                        })
                }
            )
        },
    },
    mounted() {
        this.joinGameTeam()
    },
}
</script>
